//
// GALLERY
//

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gallery-gap;
  margin-right: -$gallery-gap;
}
.gallery-item {
  --gallery-gap: #{$gallery-gap};
  padding-left: $gallery-gap;
  padding-right: $gallery-gap;
}
.gallery-item {
  width: calc((100% / var(--gallery-columns)) - (var(--gallery-gap) * (var(--gallery-columns) - 1)) / var(--gallery-columns));
  img,
  picture {
    width: 100%;
  }
  figure {
    margin-bottom: 0;
  }
}

// Gallery Item Sizes
.gallery-item-size-1 {
  --gallery-columns: 1;
}
.gallery-item-size-2 {
  --gallery-columns: 2;
}
.gallery-item-size-3 {
  --gallery-columns: 3;
}
.gallery-item-size-4 {
  --gallery-columns: 2;
  @include media-breakpoint-up('sm') {
    --gallery-columns: 4;
  }
}