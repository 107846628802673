//
// klaro
//

.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  padding: 7px 12px 3px 12px;
}

.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: var(--green3, $var-green3);
}