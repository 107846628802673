//
// BREADCRUMB
//

.breadcrumb-section {
  a {
    text-decoration: none;
  }
  .breadcrumb-link-title {
    font-weight: 700;
    text-transform: uppercase;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    @include svg-icon('#{$image-base-uri}arrow.svg', 16px, 11px, $gray-300);
    content: '';
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 10px;
    top: 3px;
    position: relative;
  }

  @include media-breakpoint-down(lg) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.stage {
  @include media-breakpoint-up(xl) {
    .header-image + .breadcrumb-section {
      position: absolute;
      right: 20px;
      top: 600px;
      z-index: 1;
    }
  }
}