//
// COLORS
//

$primary: #374BAE;
$primary-light: #AFB9E1;
$primary-bg: #c8ceea;
$primary-dark: #3C2878;
$secondary: #AA50A5;
$secondary-light: #CDA0CD;
$secondary-bg: #ddbddc;

$body-color: #1E1E46;
$link-hover-color: $primary-dark;

$white: #fff;
$warning: #D9012B;
$turquoise: #78D7F0;
$turquoise-bg: #a0e3f5;
$rose: #F0A5D7;
$rose-bg: #f4c0e3;
$orange: #FF8C87;
$orange-bg: #ffaeab;

$gray-200: #F5F5FF; //gray 1
$gray-300: #D1E2F4;
$gray-400: #D7D7E6; //gray 2
$gray-500: #8C8CA0; //gray 3
$gray-600: #555573; //gray 4