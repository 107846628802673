//
// FOOTER
//

footer {
  --bs-link-color: #{$white};
  --bs-link-hover-color: #{$turquoise};
  --bs-heading-color: #{$turquoise};
  --bs-link-color: #{$turquoise};
  background-color: $body-color;
  color: $white;
  padding: 5rem 0;

  .dropdown-toggle {
    --bs-btn-line-height: 1.2;
    display: flex;
    align-items: center;
    padding-left: 0;
    text-decoration: none;
    text-align: left;
    white-space: normal;

    @include icon-base($icon-mask: true) {
      @include svg-icon('#{$image-base-uri}icon_instagram.svg', 32px, 32px, $turquoise);
      margin-right: 12px;
    }
  }
  .dropdown-menu {
    --bs-dropdown-bg: #{$turquoise};
    --bs-dropdown-link-color: #{$body-color};
    --bs-dropdown-link-hover-color: #{$primary};
    --bs-dropdown-link-hover-bg: transparent;
    --bs-dropdown-font-size: 1rem;
    border-radius: var(--bs-dropdown-border-radius);
  }

  .footer-nav-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .navbar-nav {
    $newPadding: rem(10);
    --bs-nav-link-padding-y: #{$newPadding};
    --bs-nav-link-font-size: 1rem;
    line-height: 1.2;
  }

  .nav-link {
    --bs-nav-link-hover-color: #{$turquoise};
  }

  .d-lg-block {
    .frame {
      padding-top: 0;
    }
    .gallery-item-size-1:not(:first-child) {
      margin-top: $grid-gutter-width;
    }
  }

  @include media-breakpoint-up(lg) {
    .logo {
      margin-top: auto;
    }
    .footer-nav-wrap {
      padding-left: calc(var(--bs-gutter-x) * 2);
    }
    .d-lg-block {
      padding-left: calc(var(--bs-gutter-x) * 2);
    }
  }
  @include media-breakpoint-up(xl) {
    .footer-nav-wrap {
      padding-right: calc(var(--bs-gutter-x) * 1);
    }
  }
  @include media-breakpoint-down(lg) {
    .logo {
      align-self: start;
      max-width: 100%;
      width: auto;
      svg {
        width: 100%;
      }
    }
    .footer-nav-wrap,
    .d-lg-block {
      margin-top: $grid-gutter-width;
    }
    .gallery-item-size-1 {
      --gallery-columns: 2;
    }
  }
  @include media-breakpoint-down(md) {
    padding: $grid-gutter-width 0;

    .socials {
      margin-top: $grid-gutter-width;
    }
  }
  @include media-breakpoint-down(sm) {
    .footer-nav-wrap {
      grid-template-columns: 1fr;
      grid-row-gap: calc(#{$grid-gutter-width} / 2);
    }
    .gallery-item-size-2 {
      max-width: 120px;
    }
  }
}