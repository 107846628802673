//
// CAROUSEL
//

.carousel {
  .slick-arrow {
    background-color: $primary;
    border-radius: 50%;
    height: 56px;
    padding: 5px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 56px;
    z-index: 5;

    &:before {
      @include svg-icon('#{$image-base-uri}arrow_slider.svg', 24px, 13px, $white);
    }

    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
  .slick-prev {
    &:before {
      margin-right: 5px;
    }
  }
  .slick-next {
    &:before {
      margin-left: 4px;
    }
  }

  .slick-dots {
    --sb-slick-dots-bg: #{$white};
    bottom: -50px;

    li {
      width: 15px;

      &.slick-active button:before {
        background-color: $primary;
      }

      button:before {
        background-color: var(--sb-slick-dots-bg);
        border-radius: 50%;
        content: '';
        height: 8px;
        width: 8px;
      }
    }
  }
}

.carousel-reasons-title {
  font-size: rem(20);
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  position: absolute;
  top: 70px;
  width: 100%;
  z-index: 5;
  @include media-breakpoint-up(md) {
    top: 100px;
  }
}

.carousel-reasons {
  --sb-slick-arrow-position: 22px;
  text-align: center;

  .carousel-item .colorbox {
    display: flex;
    flex-direction: column;
    height: 520px;
    align-items: center;
    margin: 0 8px;
    padding-bottom: 100px;
    padding-top: 100px;

    @include media-breakpoint-up(md) {
      margin: 0 10px;
    }
  }
  .carousel-image {
    margin-bottom: 25px;

    img {
      height: auto;
    }

    @include media-breakpoint-down(sm) {
      height: 120px;

      img {
        max-height: 100%;
        width: auto;
      }
    }
  }

  .slick-prev {
    left: var(--sb-slick-arrow-position);
  }
  .slick-next {
    right: var(--sb-slick-arrow-position);
  }
  .slick-dots {
    --sb-slick-dots-bg: #{$gray-200};
    bottom: 30px;
  }

  @include media-breakpoint-up(lg) {
    --sb-slick-arrow-position: 72px;
  }
  @include media-breakpoint-up(xl) {
    --sb-slick-arrow-position: 152px;

    .carousel-text {
      max-width: 50vw;
    }
  }
  @include media-breakpoint-up(xxl) {
    --sb-slick-arrow-position: 250px;
  }
}

.carousel-schools {
  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
    }
  }

  .carousel-item,
  .carousel-item > div {
    height: 100%;
  }
  .carousel-item {
    background-color: $white;
    border-radius: $border-radius-big;
    overflow: hidden;
  }
  .carousel-text {
    padding: 50px 60px;
  }
  .carousel-header {
    margin-bottom: 10px;
  }
  .carousel-kicker {
    color: $primary;
    display: block;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  .carousel-image img {
    height: 100%;
    max-width: none;
    left: 50%;
    position: relative;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .slick-prev {
    left: -26px;
  }
  .slick-next {
    right: -26px;
  }
  .slick-dots {
    li:not(.slick-active) {
      button:before {
        opacity: .6;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .carousel-image {
      flex-shrink: 0;
      overflow: hidden;
      width: 360px;
    }
  }
  @include media-breakpoint-between(lg,xl) {
    .carousel-image {
      height: 300px;
      width: 100%;

      img {
        height: auto;
        width: 100%;
      }
    }
    .carousel-text {
      padding-top: 40px;
    }
  }
  @include media-breakpoint-down(lg) {
    .slick-arrow {
      display: none !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .carousel-image {
      height: 260px;
      overflow: hidden;

      img {
        min-height: 100%;
        height: auto;
        max-width: 100%;
      }
    }
    .carousel-text {
      padding: 30px 30px;
    }
  }
  @media screen and (max-width: 420px) {
    .carousel-image {
      height: 190px;
    }
  }
}