// ICON TEASER

.teaser-icon {
  color: $body-color;
  display: flex;
  font-weight: bold;
  text-decoration: none;

  img {
    margin-right: 12px;
  }
  span {
    line-height: 1.1;
    position: relative;
    top: 6px;
  }
}