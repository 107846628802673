//
// BOOTSTRAP
//

$image-base-uri: '../Images/';
$font-base-uri: '../Fonts/';

// Bootstrap 4 breakpoints & gutter
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1470px,
    xxxl: 1550px
);

$container-max-widths: (
  //sm: 540px,
  //md: 720px,
  //lg: 960px,
  //xl: 1140px,
  xxl: 1280px,
  xxxl: 1440px
);

$grid-gutter-width: 40px;

$enable-caret: false;
$enable-rounded: false;

$font-family-sans-serif: 'BRSonoma','Roboto', 'Helvetica Neue', Arial, sans-serif;
$font-size-base: 1.125rem !default;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 600;
$headings-color: $body-color;
$headings-margin-bottom: 1.563rem;

$h1-font-size: $font-size-base * 2.222 !default;
$h2-font-size: $font-size-base * 1.667 !default;
$h3-font-size: $font-size-base * 1.333 !default;
$h4-font-size: $font-size-base * 0.942 !default;

$border-radius: 8px;
$border-radius-big: 16px;

$paragraph-margin-bottom: 1.25rem;

$navbar-padding-y: 10px;
$navbar-nav-link-padding-x: 25px;
$navbar-light-color: $gray-600;
$navbar-light-hover-color: $body-color;
$navbar-light-active-color: $primary;

$navbar-light-toggler-border-color: $primary;
$navbar-light-toggler-icon-bg: none;
$navbar-toggler-padding-x: 21px;
$navbar-toggler-padding-y: 20px;
$navbar-toggler-focus-width: 0;

$nav-link-font-weight: 400;
//$nav-link-padding-y: 0.625rem;

$dropdown-padding-y: 0;
$dropdown-min-width: 15rem;
$dropdown-border-width: 0;
$dropdown-bg: $primary;
$dropdown-color: #fff;
$dropdown-link-color: #fff;
$dropdown-link-hover-bg: $primary-dark;
$dropdown-link-active-color: $dropdown-link-color;
$dropdown-link-active-bg: $primary-dark;

$dropdown-item-padding-x: $navbar-nav-link-padding-x;
$dropdown-item-padding-y: 7px;

$breadcrumb-font-size: 1rem;
$breadcrumb-item-padding-x: 10px;

$input-border-radius: $border-radius;
$input-border-color: $gray-400;
$input-focus-border-color: $primary;
$input-padding-x: 15px;

$btn-border-width: 2px;
$btn-padding-x: 24px;
$btn-padding-y: 12px;
$btn-font-size: 1rem;
$btn-font-weight: 600;

$gallery-gap: 20px;

$accordion-padding-x: 1rem;
$accordion-padding-y: 20px;
$accordion-border-color: $gray-400;
$accordion-border-width: 2px;
$accordion-button-bg: transparent;
$accordion-button-active-bg: $accordion-button-bg;
$accordion-button-active-color: $primary;
$accordion-button-icon: none;
$accordion-button-active-icon: none;
$accordion-button-focus-box-shadow: none;
$accordion-icon-transform: rotate(-90deg);

$pagination-border-width: 0;
$pagination-color: $body-color;
$pagination-hover-color: $secondary;
$pagination-active-bg: $gray-200;
$pagination-active-color: $secondary;

// number of cards per line for each breakpoint
$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3
);