//
// NAVIGATION META
//

.meta-wrap {
  .nav {
    --bs-nav-link-color: #{$gray-600};
    --bs-nav-link-font-size: 1rem;
    //--bs-nav-link-font-weight: 600;
    --bs-nav-link-padding-x: 20px;
  }
  .nav-item:first-child {
    --bs-nav-link-color: #{$primary};
    --bs-nav-link-font-weight: 700;
  }
  .nav-item:not(:first-child) .nav-link {
    padding-left: 25px;

    &:before {
      background-color: $gray-400;
      content: '';
      display: block;
      border-radius: 2px;
      height: 8px;
      left: 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
    }
  }
  .nav-link {
    position: relative;
  }
}

@include media-breakpoint-down(lg) {
  header .meta-menu {
    .nav-item:first-child .nav-link {
      color: $secondary;
    }
  }
}