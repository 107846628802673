//
// NEWS
//

.tx-ttaddress {
  .address-title {
    display: block;

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }
  }
  .address-phone {
    color: $body-color;
    text-decoration: none;
  }

  .text-block {
    page-break-inside: avoid;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .text-small {
    font-size: 1rem;
    hyphens: auto;
  }

  .info-email {
    hyphens: auto;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tiles .tx-ttaddress {
  @include media-breakpoint-down(xl) {
    padding-bottom: $grid-gutter-width;
  }
}

.tx-ttaddress,
.address-mode-secretariat,
.address-mode-contacts,
.address-wrap {
  height: 100%;
}

.address-mode-secretariat,
.address-mode-contacts {
  .address-wrap {
    background-color: $white;
    border-radius: $border-radius;
    padding: 50px $grid-gutter-width;
  }
  .address-image-wrap {
    display: flex;
    grid-column-gap: 15px;
  }
  .image {
    border-radius: 50%;
    height: 134px;
    overflow: hidden;
    width: 134px;

    @include media-breakpoint-down(sm) {
      height: 120px;
      width: 120px;
    }
  }
  .more-link {
    display: block;
  }
  .address-info + .more-link {
    margin-top: 20px;
  }
}

.frame > .tx-ttaddress .address-mode-contacts {
  display: grid;
  gap: 40px;
  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.column-right .tx-ttaddress {
  .address-title {
    margin: 0;
  }

  .address-mode-secretariat {
    font-size: 1rem;
    .team-item + .team-item {
      margin-top: 10px;
    }

    .address-info .text-block:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}