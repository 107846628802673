//
// ADDRESS MAP
//

.address-map {
  position: relative;

  h3 {
    --bs-heading-color: #{$primary};
    font-size: 1rem;
    text-transform: uppercase;

  }
  .open-street-map {
    bottom: 2px;
    font-size: 14px;
    position: absolute;
    right: 5px;
    text-decoration: none;
  }

  @include media-breakpoint-up(xl) {
    .container {
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  @include media-breakpoint-down(xl) {
    background-color: $white;
    display: flex;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.address-map-box {
  background-color: $white;
  border-radius: $border-radius;
  padding: 30px 10px;

  .small {
    border-top: 2px solid $gray-400;
    margin-top: 30px;
    padding-top: 30px;
  }
  .more-link {
    margin-top: $grid-gutter-width;
  }
  @include media-breakpoint-up(sm) {
    padding: 50px;
  }
  @include media-breakpoint-up(md) {
    width: 400px;
  }
  @include media-breakpoint-up(xl) {
    width: 480px;
  }
}

.address-map-image {
  display: flex;
  align-items: center;
  max-height: 720px;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    img {
      max-width: none;
      min-width: 100%;
    }
  }
  @include media-breakpoint-down(xl) {
    justify-content: end;

    img {
      height: 100%;
      max-width: none;
      margin-right: -16vw;
    }
  }
  @include media-breakpoint-down(md) {
    img {
      margin-right: 0;
    }
  }
}

.frame-type-address_map:last-child {
  padding-bottom: 0;
  margin-bottom: calc((var(--frame-spacing-xs) + 1px) * -1);

  @include media-breakpoint-up(sm) {
    margin-bottom: calc((var(--frame-spacing-sm) + 1px) * -1);
  }
  @include media-breakpoint-up(md) {
    margin-bottom: calc((var(--frame-spacing-md) + 1px) * -1);
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: calc((var(--frame-spacing-lg) + 1px) * -1);
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: calc((var(--frame-spacing-xl) + 1px) * -1);
  }
}