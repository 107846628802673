
/*------------------------------------*\
    #ZITAT
    #modul_blockquote
\*------------------------------------*/
.modul_blockquote,
.stage-item {
  .portrait{
    border-radius: 50%;
    max-width: 100%;
  }
}
.modul_blockquote {
  .portrait {
    float: left;
    margin-right: 55px;
    -webkit-shape-outside:circle();
    shape-outside:circle();
  }
}



