//
// NAVIGATION MAIN
//

#main-navigation {
  --bs-nav-link-font-size: 1.25rem;
  @include media-breakpoint-up(lg) {
    padding-bottom: 30px;
  }
}
#main-menu {
  .navbar-toggler {
    position: absolute;
    right: 20px;
    top: 36px;
    z-index: 10;
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      border-radius: $border-radius;
      overflow: hidden;
    }
    .nav-item:last-child a {
      padding-right: 0;
    }
  }
  @include media-breakpoint-between(lg,xl) {
    --bs-navbar-nav-link-padding-x: 20px;
  }
  @include media-breakpoint-down(lg) {
    background: $white;
    border-left: 2px solid $primary;
    height: 100vh;
    right: -51vw;
    overflow-y: scroll;
    padding-top: 80px;
    position: fixed;
    top: 0;
    transition: right .4s ease-in;
    width: 50vw;
    z-index: 2000;

    &.show {
      right: 0;
    }
    .navbar-nav {
      --bs-nav-link-padding-x: 20px;
      --bs-nav-link-padding-y: .375rem;
    }
    .nav-link {
      --bs-navbar-active-color: #{$primary};
    }
    .dropdown-menu {
      --bs-dropdown-bg: #{$gray-300};
      --bs-dropdown-link-color: #{$body-color};
      --bs-dropdown-link-hover-color: #{$white};
      --bs-dropdown-link-hover-bg: #{$primary};
      --bs-dropdown-link-active-color: #{$white};
      --bs-dropdown-link-active-bg: #{$primary};
      --bs-dropdown-item-padding-x: 40px;
    }

    .meta-menu,
    .meta-menu + .navbar-nav {
      --bs-nav-link-padding-y: 5px;
      margin: 20px 0;
    }
    .meta-menu + .navbar-nav {
      --bs-nav-link-font-size: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    right: -71vw;
    width: 70vw;
  }
  @include media-breakpoint-down(sm) {
    right: -91vw;
    width: 90vw;
  }
}

.dropdown-menu {
  li:first-child a {
    padding-top: 20px;
  }
  li:last-child a {
    padding-bottom: 20px;
  }
}
.dropdown-item {
  line-height: 1.25;
  white-space: normal;
}

.navbar-toggler {
  --bs-border-width: 0;
  background-color: $primary;
  border-radius: $border-radius;
  position: relative;

  &.collapsed {
    &:before {
      height: 3px;
    }
  }
}
.navbar-toggler-icon:after,
.navbar-toggler-icon,
.navbar-toggler-icon:before {
  content: "";
  position: absolute;
  top: 0;
  background-color: $white;
  background-image: none !important;
  transition: all 0.3s ease-in;
}
.navbar-toggler-icon {
  height: 0;
  left: 10px;
  top: 19px;
  transform: none;
  width: 22px;

  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  height: 2.5px;
  left: -3px;
  transition-delay: 0.1s;
  width: 28px;
}
.navbar-toggler.collapsed {
  .navbar-toggler-icon,
  .navbar-toggler-icon:after,
  .navbar-toggler-icon:before {
    height: 2px;
    transform: none;
    width: 22px;
  }
  .navbar-toggler-icon:after,
  .navbar-toggler-icon:before {
    left: 0;
  }
  .navbar-toggler-icon:before {
    top: -7px;
  }
  .navbar-toggler-icon:after {
    top: 7px;
  }
}

.collapsing {
  height: auto;
  position: inherit;
  transition: height 0.01s;
}