//
// GLOBAL
//

:root {
  --sb-headline-intro-color: #{$primary};
  --sb-headline-intro-line: #{$turquoise};
}

body {
  @include media-breakpoint-down(md) {
    --bs-body-font-size: 1rem;
  }
}

main {
  /*for anchor-link offset: padding and negative margin*/
  > a:not([href]):not(.nav-link) {
      padding-top: 170px;
      margin-top: -170px;
  }
  a[data-mailto-token] {
    word-break: break-all;
  }
}

p {
  &:last-child:not(.h2,.h3) {
    margin-bottom: 0;
  }
  + h2,
  + h3 {
    margin-top: 50px;
    @include media-breakpoint-down(md) {
      margin-top: $grid-gutter-width;
    }
  }
}

b, strong {
  font-weight: 600;
}

@include media-breakpoint-down(sm) {
  a {
    hyphens: auto;
  }
}

figure {
  border-radius: $border-radius;
}

h1 {
  line-height: 1.1;

  .headline-intro {
    font-size: rem(20);
    letter-spacing: 1.6px;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
}

.headline-intro {
  display: block;
  font-size: 1rem;
  hyphens: auto;
  letter-spacing: 1.3px;
  margin-bottom: 28px;
  text-transform: uppercase;

  @include icon-base($icon-mask: true, $position: relative, $show: after, $display: block) {
    @include svg-icon('#{$image-base-uri}line_short.svg', 16px, 94px, var(--sb-headline-intro-line));
    margin-top: 20px;
  }

  @include media-breakpoint-down(md) {
    font-size: .875rem;

    &:after {
      margin-top: 15px;
      width: 40px;
    }
  }
}
.text-center {
  .headline-intro:after {
    transform: translateX(-50%);
    left: 50%;
  }
}
.headline {
  display: block;
}

.stage {
  position: relative;

  &:not(.stage-no-image) {
    padding-bottom: $grid-gutter-width + 10;
  }

  @include media-breakpoint-down(lg) {
    --bs-gutter-x: 0;
    padding-bottom: 50px;
  }
  @include media-breakpoint-down(md) {
    &:not(.stage-no-image) {
      padding-bottom: 20px;
    }
  }
}
@include media-breakpoint-up(xl) {
  .home .stage {
    padding-bottom: 80px;
  }
}
main > .container:first-child:not(.stage) {
  margin-top: $grid-gutter-width;
}

.column-left,
.column-right {
  .frame > .row {
    justify-content: center;
  }
}
.column-right {
  h3 {
    margin-bottom: 20px;
  }
  .btn-primary {
    --bs-btn-font-size: var(--bs-body-font-size);
    --bs-btn-padding-y: 19px;
    text-align: center;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 30px;
    h2:not(.news-title) {
      font-size: rem(16);
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
    .frame {
      --frame-spacing: 20px;
    }
  }
}

.pagination {
  .page-link {
    border-radius: $border-radius;
  }
  .page-item {
    padding-right: 5px;
  }
}