//
// NEWS
//

.news-base {
  .list {
    --sb-news-list-cat: #{$secondary};
    --sb-news-list-bg: #{$gray-200};
    --sb-news-list-color: inherit;
    display: grid;
    margin-bottom: $grid-gutter-width;
    row-gap: calc($grid-gutter-width / 2);

    .news-title {
      font-size: rem(20);
    }
    .news-cat {
      color: var(--sb-news-list-cat);
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .date {
      color: var(--sb-news-date-color);
      font-size: rem(14);
      margin-top: auto;
    }

    a:not(.more-link) {
      --sb-news-date-color: #{$gray-600};
      background-color: var(--sb-news-list-bg);
      border-radius: $border-radius;
      display: flex;
      height: 100%;
      flex-direction: column;
      text-decoration: none;
      transition: all .3s;
      padding: 30px 26px;

      &:hover {
        --sb-news-list-cat: #{$white};
        --sb-news-list-bg: #{$secondary};
        --sb-news-date-color: #{$white};

        h2, h3 {
          --bs-heading-color: #{$white};
        }
      }
    }
    h2, h3 {
      --bs-heading-color: #{$body-color};
    }

    @include media-breakpoint-up(md) {
      .list .news-title {
        font-size: $font-size-base;
      }
    }
  }
}

.job-list {
  .news-title {
    font-family: $font-family-base;
    margin: 0;
  }
}

.news-list-wrap {
  display: flex;
  flex-direction: column;

  .list {
    margin-bottom: 0;
    grid-template-columns: 80% 80% 80%;
  }

  @include media-breakpoint-up(sm) {
    .list {
      grid-template-columns: 60% 60% 60%;
    }
  }
  @include media-breakpoint-up(md) {
    .list {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @include media-breakpoint-up(xl) {
    flex-direction: row;

    .news-item-title {
      margin-right: calc($grid-gutter-width / 2);
    }
  }
  @include media-breakpoint-down(xl) {
    .news-item-title {
      margin-bottom: calc($grid-gutter-width / 2);
      padding-bottom: 30px;
    }
  }
}

.news-list,
.events-list {
  column-gap: calc($grid-gutter-width / 2);
  .news-title {
    line-height: 1.3;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @include media-breakpoint-down(md) {
    overflow-x: scroll;
  }
}

.news-item-title {
  --bs-heading-color: #{$white};
  --bs-link-color: #{$white};
  background-color: $primary;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;

  .btn-link {
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 4px;
    --bs-btn-active-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-border-width: 0;
    --bs-btn-active-border-color: #{$white};
    --bs-btn-hover-border-color: #{$white};
    font-size: rem(26);
    line-height: 1;
    text-decoration: none;

    &:first-child {
      margin-bottom: 10px;
    }
  }
  .more-link {
    --bs-btn-color: #{$white};
    --bs-btn-hover-color: #{$rose};
    --sb-btn-icon-color: #{$white};
    --sb-btn-hover-icon-color: #{$rose};
  }
  @include media-breakpoint-up(md) {
    h2, .btn-link {
      font-size: rem(32);
    }
  }

}
.home .news-item-title {
  .btn-link {
    --bs-btn-color: #d8daef;
    --sb-headline-intro-line: #{$primary};
    @include icon-base($icon-mask: true, $position: relative, $show: after, $display: block) {
      @include svg-icon('#{$image-base-uri}line_news.svg', 13px, 89px, var(--sb-headline-intro-line));
    }
    text-align: left;
    width: 100%;

    &:after {
      transition: background-color .15s ease-in-out;
    }

    &.active, &:hover {
      --sb-headline-intro-line: #{$rose};
    }
  }
  @include media-breakpoint-up(md) {
    padding: 40px 45px;
  }
  @include media-breakpoint-up(xl) {
    min-height: 245px;
    min-width: 300px;

    .more-link {
      margin-top: auto;
      margin-bottom: -20px;
    }
  }
  @include media-breakpoint-down(xl) {
    .more-link {
      margin-left: auto;
      margin-top: -42px;
    }
  }
  @include media-breakpoint-down(md) {
    .more-link {
      margin-top: -38px;
    }
  }
}

// column right

.column-right .news-base {
  .list {
    margin-bottom: 0;
    row-gap: 10px;

    a:not(.more-link) {
      padding: 30px;
    }
  }
  .news-item-title {
    margin-bottom: 10px;
    padding-bottom: 24px;
    padding-top: 24px;

    h2 {
      margin: 0;
    }
    @include media-breakpoint-up(lg) {
      h2 {
        font-size: 1rem;
      }
    }
  }
  .more-link {
    margin-left: auto;
    margin-top: -30px;
  }
}
