//
// TEXTPIC
//

.image-left,
.image-right {
  figure {
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 5px;
  }
}

.image-left {
  @include media-breakpoint-up(xl) {
    float: left;
    margin-right: 20px;
  }
}
.image-right {
  @include media-breakpoint-up(xl) {
    float: right;
    margin-left: 20px;
  }
}

.textpic-above,
.textpic-below {
  .single-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.gallery-row + p {
  margin-top: 20px;
}

.caption {
  color: $gray-600;
  font-size: 1rem;
  margin-top: 10px;
}