//
// FONTS
//

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
* © 2024 MyFonts Inc. */

// BRSonomaRegular
@font-face {
  font-family: "BRSonoma";
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-base-uri}BRSonomaRegular/font.woff2') format('woff2'), url('#{$font-base-uri}BRSonomaRegular/font.woff') format('woff');
}
// BRSonomaSemiBold
@font-face {
  font-family: "BRSonoma";
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-base-uri}BRSonomaSemiBold/font.woff2') format('woff2'), url('#{$font-base-uri}BRSonomaSemiBold/font.woff') format('woff');
}
// BRSonomaBold
@font-face {
  font-family: "BRSonoma";
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-base-uri}BRSonomaBold/font.woff2') format('woff2'), url('#{$font-base-uri}BRSonomaBold/font.woff') format('woff');
}

/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-base-uri}roboto-v32-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-base-uri}roboto-v32-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-base-uri}roboto-v32-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}