//
// INDEXEDSEARCH
//

.tx_indexedsearch {
  .tx-indexedsearch-searchbox-sword {
    border-radius: $border-radius 0 0 $border-radius;
    border-right: 0;
    font-size: 1rem;

    &::-webkit-input-placeholder {
      color: $gray-600;
    }
    &:-moz-placeholder {
      color: $gray-600;
    }
    &::-moz-placeholder {
      color: $gray-600;
    }
    &:-ms-input-placeholder {
      color: $gray-600;
    }
    &::placeholder {
      color: $gray-600;
    }
  }
  .btn {
    border: var(--bs-border-width) solid $input-border-color;
    border-radius: 0 $border-radius $border-radius 0;
    border-left: 0;

    &:before {
      background-color: $gray-500;
    }
  }
}

.tx-indexedsearch-info-sword {
  margin-top: 20px;
}
.tx-indexedsearch-browsebox {
  display: flex;
  gap: 15px;
  list-style: none;
  margin: $grid-gutter-width 0;
  padding: 0;

  a {
    text-decoration: none;
  }
}

.tx-indexedsearch-res {
  border-top: 1px solid $gray-300;
  margin-top: calc($grid-gutter-width / 2);
  padding-top: calc($grid-gutter-width / 2);

  &:last-of-type {
    border-bottom: 1px solid $gray-300;
  }
  h3 {
    margin-bottom: calc($grid-gutter-width / 2);
  }
  a {
    text-decoration: none;
  }
}
.tx-indexedsearch-info {
  font-size: 1rem;
}

#main-navigation .tx_indexedsearch {
  margin: 15px 20px;
}

.home .tx-indexedsearch-searchbox-sword {
  background-color: transparent;
}