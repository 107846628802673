//
// Icons
//

.icon,
.icon-mask {
  line-height: 1;
}
.icon-mask {
  @include icon-base($icon-mask: true);
}
.icon {
  @include icon-base($icon: true);
}

.i-search {
  &:before {
    @include svg-icon('#{$image-base-uri}search.svg', 18px, 18px, $primary);
    top: 1px;
  }
}
.i-arrow {
  &:after {
    @include svg-icon('#{$image-base-uri}arrow.svg', 20px, 15px, $primary);
    margin-left: 30px;
    top: 2px;
  }
}