//
// ACCORDION
//

.accordion-item {
  border-left: 0;
  border-right: 0;
}
.accordion-button {
  font-weight: 600;

  &:not(.collapsed) {
    --bs-accordion-border-width: 0;
  }

  &:after {
    @include svg-icon('#{$image-base-uri}arrow.svg', 15px, 11px, $primary);
    align-self: end;
    display: inline-block;
    background-position: 0 0;
    background-size: contain;
    margin-bottom: 2px;
    transform: rotate(90deg);
  }
  &:hover {
    --bs-accordion-btn-color: #{$primary};
  }

  span {
    padding-right: 12px;
  }
}
.accordion-body {
  border-top: 3px solid $primary;
}