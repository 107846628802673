//
// Form
//

.formgenerator {
  display: flex;
  flex-direction: column;

  .btn {
    align-self: flex-end;
  }

  // server errors
  .help-block.error {
    color: $red;
  }
  .error {
    border-color: $red;
  }
  //parsley errors
  .parsley-error {
    border-color: $red;

    &.custom-file-input + .custom-file-label {
      border-color: $red;
    }
    &.custom-checkbox-multiple,
    &.custom-radio-multiple {
      .form-check-input {
        border-color: $red;
      }
    }
  }
  .parsley-errors-list {
    color: $red;
    list-style: none;
    margin: 0;
    padding: 0;

    &.filled + .custom-file-label {
      border-color: $red;
    }
  }
}

fieldset.form-group {
  margin: 0;
}

.form-group {
  margin-bottom: 1.2rem;
}
.form-group,
.form-row {
  + .clearfix {
    margin-top: $grid-gutter-width;
  }
}

.birthday-wrap {
  background-color: $gray-300;
  display: none;
  padding: 20px 20px 10px 20px;

  + * {
    margin-top: 20px;
  }
}

.form-control,
.form-select {
  border-radius: 8px;
}
.form-check-input[type="checkbox"] {
  border-radius: 3px;
}

.form-check-input-wrap,
.custom-control-input-wrap {
  .control-label {
    margin-bottom: 10px;
  }
}
.form-check {
  padding-left: 1.8em;

  .form-check-input {
    margin-left: -1.8em;
  }
}
.radio {
  .form-check-label {
    padding-left: 12px;
  }
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
.custom-file-input {
  cursor: pointer;
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  overflow: hidden;
  opacity: 0;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;

  &:after {
    background-color: $turquoise-bg;
    content: attr(data-browse-label);
    font-size: 14px;
    padding: 10px 14px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 3;
    display: block;
    border-left: inherit;
  }
}
.add_field_button {
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.custom-file-input-wrap {
  display: none;
  &.show {
    display: block;
  }
}

.form-actions {
  margin-top: $grid-gutter-width;
}