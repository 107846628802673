//
// SLICK SLIDER
//
.slick-wrap + p {
  margin-top: $grid-gutter-width;
}

.slick-slider {
  .slide-wrap {
    margin: 0 calc($grid-gutter-width / 2) 0 0;
  }
  figure {
    margin: 0;
  }
  @include media-breakpoint-up(md) {
    img {
      height: 440px;
      width: auto;
    }
  }
}

.slick-navigation {
  display: flex;
  margin-top: 10px;
}
.slick-numbers {
  font-weight: 800;
}
.slick-arrow {
  @include icon-base($icon-mask: true);
  top: 4px;
  transform: none;
  width: 30px;

  &:before {
    @include svg-icon('#{$image-base-uri}arrow.svg', 16px, 12px, $primary);
  }
}

.slick-prev {
  left: 0;
  padding-right: 20px;

  &::before {
    transform: rotate(180deg);
  }
}
.slick-next {
  padding-left: 20px;
  right: 0;
}