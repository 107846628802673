//
// HEADER IMAGE
//

.header-image {
  display: flex;
  flex-direction: column;
  position: relative;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;

    .image-wrap {
      margin-left: -600px;
      order: 2;
    }
  }

  .image-wrap {
    overflow: hidden;
  }
  .gallery-item {
    height: 300px;

    figure {
      height: 100%;
    }
    img {
      height: auto;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      max-width: none;
    }

    @include media-breakpoint-up(md) {
      height: 480px;

      img {
        min-height: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      height: 540px;
      figure {
        text-align: right;
      }
      img {
        height: 100%;
        width: auto;
      }
    }
    @include media-breakpoint-up(xl) {
      height: 640px;
    }
    @include media-breakpoint-down(sm) {
      img {
        min-height: 100%;
        min-width: 100%;
        width: auto;
      }
    }
  }

  .header-box {
    --sb-header-box-bg: #{$primary};
    --sb-header-box-bg: #{$primary};
    padding: 25px 20px 30px 20px;
    position: relative;

    &::before {
      background-color: var(--sb-colorbox-bg);
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    @include media-breakpoint-up(md) {
      margin-top: -70px;
      padding: 40px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 20%;
      padding: 80px;

      &:before {
        border-radius: $border-radius-big;
        opacity: .97;
      }
    }
    @include media-breakpoint-up(xxl) {
      margin-top: 20%;
      padding: 85px 100px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-top: 250px;
    }
    @include media-breakpoint-down(lg) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .header-box-text {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      font-size: rem(22);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(24);
    }
  }
  h1:last-child {
    margin-bottom: 0;
  }
}

.home {
  .stage {
    &:before {
      background-color: #F1FBFD;
      content: '';
      min-height: 100%;
      position: absolute;
      width: 100vw;
      z-index: 0;

      @include media-breakpoint-up(lg) {
        bottom: -80px;
        top: -173px;
        z-index: -1;
      }
    }
  }
  .header-image {
    .headline:first-child {
      @include icon-base($icon-mask: true, $position: relative, $show: after, $display: block) {
        @include svg-icon('#{$image-base-uri}line_long.svg', 16px, 100%, var(--sb-headline-intro-line));
        -webkit-mask-position: left;
        mask-position: left;
      }
    }

    .image-wrap {
      @include media-breakpoint-up(lg) {
        margin-left: -50%;
      }
    }
    .gallery-item {
      @include media-breakpoint-up(lg) {
        figure {
          text-align: left;
        }
      }
      @include media-breakpoint-up(xxl) {
        height: 630px;
      }
    }
    .header-box {
      @include media-breakpoint-up(xxxl) {
        margin-top: 380px;
      }
    }
    img {
      @include media-breakpoint-up(2000px) {
        left: 0;
        -webkit-transform: none;
        transform: none;
      }
    }

    @include media-breakpoint-between(lg,xl) {
      .header-box-text {
        background-color: #F1FBFD;
        border-radius: $border-radius-big;
      }
    }
    @include media-breakpoint-down(xl) {
      .header-box-text {
        padding: $grid-gutter-width 0;
      }
    }
  }
}