//
// LISTS
//

:root {
  --sb-list-dot-bg: #{$turquoise};
}

.list {
  list-style: none;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.downloadlist {
  li {
    margin-bottom: 10px;
  }
  a {
    border: 2px solid $primary;
    border-radius: $border-radius;
    display: block;
    font-weight: 600;
    padding: 20px 16px 20px 62px;
    position: relative;
    text-decoration: none;
    transition: border-color .3s ease-in-out;
    word-break: break-all;

    @include icon-base($icon: true, $position: absolute) {
      @include svg-icon('#{$image-base-uri}icon_download.svg', 29px, 20px, var(--frame-link-color));
      left: 20px;
      top: 22px;
    }

    &:hover {
      --frame-link-hover-color: #{$white};
      background-color: $primary;

      &:before {
        background-color: $white;
      }
      .download-info {
        color: $white;
      }
    }
  }
  .download-info {
    color: $gray-600;
    font-size: rem(14);
    font-weight: 400;
  }
}

.standardlist,
.numberedlist {
  > li:not(:first-child) {
    margin-top: 8px;
  }
  > li {
    position: relative;
    &:before {
      display: inline-block;
      left: 0;
      position: absolute;
    }
  }

  .standardlist,
  .numberedlist {
    margin: 20px 0;
  }
}
.numberedlist {
  counter-reset: numbered-list-counter;
  padding-left: 6px;

  li {
    counter-increment: numbered-list-counter;
    padding-left: 26px;

    &:before {
      content: counter(numbered-list-counter);
      color: var(--sb-list-dot-bg);
    }
  }
}
.standardlist {
  > li {
    padding-left: 32px;
    &:before {
      background: var(--sb-list-dot-bg);
      border-radius: 2px;
      content: '';
      height: 11px;
      left: 4px;
      top: 9px;
      transform: rotate(45deg);
      width: 11px;
    }
  }
}