//
// BUTTONS
//

.btn {
  border-radius: $border-radius;
  break-inside: avoid;
  text-align: left;
}

.btn-arrow {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 1rem;
  --bs-btn-color: #{$primary};
  --bs-btn-bg: transparent;
  --bs-btn-border-color: #{$primary};
  --bs-btn-hover-bg: #{$primary};
  --bs-btn-hover-border-color: #{$primary};
  --bs-btn-hover-color: #{$white};
  --bs-btn-icon-color: #{$primary};
  --bs-btn-hover-icon-color: #{$white};
  @include icon-base($icon-mask: true, $show: after, $position: absolute);
  hyphens: auto;
  padding-right: calc(var(--bs-btn-padding-x) * 2 + 5px);

  &:after {
    @include svg-icon('#{$image-base-uri}arrow.svg', 18px, 14px, var(--bs-btn-icon-color));
    right: var(--bs-btn-padding-x);
  }
  &:hover {
    &:after {
      --bs-btn-icon-color: var(--bs-btn-hover-icon-color);
    }
  }

  &.btn-arrow-secondary {
    --bs-btn-icon-color: #{$secondary};
    --bs-btn-hover-icon-color: #{$secondary};
  }
  &.btn-arrow-turquoise {
    --bs-btn-icon-color: #{$turquoise};
    --bs-btn-hover-icon-color: #{$turquoise};
  }
  &.btn-arrow-rose {
    --bs-btn-icon-color: #{$rose};
    --bs-btn-hover-icon-color: #{$rose};
  }
  &.btn-arrow-orange {
    --bs-btn-icon-color: #{$orange};
    --bs-btn-hover-icon-color: #{$orange};
  }

  + .btn-arrow {
    margin-top: 20px;
  }
}

.text-column,
.column-right {
  .btn-arrow {
    display: flex;
    justify-content: space-between;
  }
}

// Textlink with arrow
.more-link {
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
  --bs-btn-color: #{$primary};
  --sb-btn-icon-color: #{$primary};
  --sb-btn-hover-icon-color: #{$primary-dark};

  @include icon-base($icon-mask: true, $show: after) {
    @include svg-icon('#{$image-base-uri}arrow.svg', 14px, 10px, var(--sb-btn-icon-color));
    margin-left: 15px;
    transition: background-color .2s;
    top: 2px;
  }
  display: inline-block;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  position: relative;
  text-decoration: none;
  transition: color .2s;

  &:hover {
    text-decoration: underline;

    &:after {
      background-color: var(--sb-btn-hover-icon-color);
    }
  }

  &-secondary {
    --sb-btn-icon-color: #{$secondary};
    --sb-btn-hover-icon-color: #{$secondary};
  }
  &-turquoise {
    --sb-btn-icon-color: #{$turquoise};
    --sb-btn-hover-icon-color: #{$turquoise};
  }
  &-rose {
    --sb-btn-icon-color: #{$rose};
    --sb-btn-hover-icon-color: #{$rose};
  }
  &-orange {
    --sb-btn-icon-color: #{$orange};
    --sb-btn-hover-icon-color: #{$orange};
  }

  @include media-breakpoint-down(sm) {
    text-wrap-style: balance;
  }
}