//
// FRAME
//

$frame-color:                   inherit !default;
$frame-background:              transparent !default;
$frame-width-large:             1600px !default;
$frame-width-medium:            870px !default;
$frame-width-small:             600px !default;
$frame-inner-spacing:           1.667rem !default;
$frame-inner-spacing-small:     .75rem !default;
$frame-inner-spacing-scaling:   () !default;
$frame-inner-spacing-scaling:   map-merge(
  (
      xs: 1,
      sm: 1.2,
      md: 1.3,
      lg: 1.5,
      xl: 1.9
  ),
  $frame-inner-spacing-scaling
);
$frame-outer-spacing:           1rem !default;
$frame-outer-spacing-scaling:  () !default;
$frame-outer-spacing-scaling:  map-merge(
  (
    'none': 0,
    'extra-small': 1,
    'small': 1.5,
    'medium': 2,
    'large': 2.5,
    'extra-large': 3
  ),
  $frame-outer-spacing-scaling
);

//
// CSS Variables
// --------------------------------------------------
:root {
    --frame-color: #{$frame-color};
    --frame-link-color: #{$link-color};
    --frame-link-hover-color: #{$link-hover-color};
    --frame-background: #{$frame-background};
    --frame-width-large: #{$frame-width-large};
    --frame-width-medium: #{$frame-width-medium};
    --frame-width-small: #{$frame-width-small};
    --frame-spacing: #{$frame-inner-spacing};
    --frame-spacing-small: #{$frame-inner-spacing-small};
    @each $breakpoint in map-keys($frame-inner-spacing-scaling) {
        --frame-spacing-#{$breakpoint}: #{$frame-inner-spacing * map-get($frame-inner-spacing-scaling, $breakpoint)};
        --frame-spacing-small-#{$breakpoint}: #{$frame-inner-spacing-small * map-get($frame-inner-spacing-scaling, $breakpoint)};
    }
    --frame-outer-spacing-before: 0;
    --frame-outer-spacing-after: 0;
    @each $variant in map-keys($frame-outer-spacing-scaling) {
        --frame-outer-spacing-variant-#{$variant}: #{$frame-outer-spacing * map-get($frame-outer-spacing-scaling, $variant)};
    }
}

//
// Frame
// --------------------------------------------------
.frame {
    position: relative;
    margin-top: var(--frame-outer-spacing-before);
    margin-bottom: var(--frame-outer-spacing-after);
    padding-top: var(--frame-spacing);
    padding-bottom: var(--frame-spacing);
    color: var(--frame-color);
    background: var(--frame-background);
    a[class=""],
    a:not([class]) {
        color: var(--frame-link-color);
        &:hover {
            color: var(--frame-link-hover-color);
        }
    }
    @each $breakpoint in map-keys($frame-inner-spacing-scaling) {
        @include media-breakpoint-up($breakpoint) {
            --frame-spacing: var(--frame-spacing-#{$breakpoint});
        }
    }
}