//
// MENU SECTION (JUMP NAVIGATION)
//

.menu-section {
  --bs-nav-link-padding-x: 30px;
  --bs-nav-link-padding-y: 20px;
  --bs-nav-link-color: #{$primary};
  --bs-nav-link-hover-color: #{$primary};
  --bs-nav-link-font-weight: 400;

  .nav-title {
    --bs-nav-link-padding-y: 24px;
    background-color: $gray-200;
    margin-bottom: 0;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  }

  .nav-link {
    border-top: 1px solid $gray-400;
    line-height: 1.3;

    &:last-child {
      border-bottom: 1px solid $gray-400;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-down(lg) {
  .frame-type-menu_section {
    display: none;
  }
}