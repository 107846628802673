//
// HEADER
//

header {
  padding-top: 15px;
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width;
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 15px;
  }

  .logo {
    display: inline-block;
    padding-top: 5px;
    width: 180px;

    @include media-breakpoint-up(lg) {
      padding-top: 10px;
      width: 240px;
    }
    @include media-breakpoint-up(xxl) {
      width: 316px;
    }
  }
}