//
// RTE PLUGIN COLUMNS
//

.text-column {
  margin-bottom: 1rem;

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .text-column {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: $grid-gutter-width;
    -moz-column-gap: $grid-gutter-width;
    column-gap: $grid-gutter-width;
  }
}