//
// CONFIGURATOR
//

.module_colortabs {
  .colorbox {
    height: 100%;
    @include icon-base($icon: true, $position: relative) {
      margin-bottom: 20px;
    };
  }
  h2 {
    margin-bottom: 20px;
  }
  .frame-background-primary {
    --bs-btn-active-border-color: #{$primary};
    .colorbox:before {
      @include svg-icon('#{$image-base-uri}icon_abschluss.svg', 73px, 62px, $primary);
    }
  }
  .frame-background-secondary {
    --bs-btn-active-border-color: #{$secondary};
    .colorbox:before {
      @include svg-icon('#{$image-base-uri}icon_berufsfelder.svg', 72px, 66px, $secondary);
    }
  }

  @include media-breakpoint-down(md) {
    > div:last-child {
      margin-top: $grid-gutter-width;
    }
    .colorbox {
      width: 100%;
    }
  }
}

.module_configurator {
  .configurator_response, #step2 {
    display: none;
  }
  #step3 {
    margin-top: $grid-gutter-width;
  }
}

// local configuration: #c135, #c137
// live configuration: #c247, #c248
#c135, #c137,
#c247, #c248 {
  display: none;
}