//
// TEASER IMAGE
//

.tiles {
  .teaser-item {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter-width;
    position: relative;
    text-decoration: none;

    &:hover {
      .overlay {
        background: $primary;
        color: $white;
      }
    }
    .overlay {
      background: $gray-200;
      display: flex;
      min-height: 64px;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
      padding: 10px;
      text-align: center;
      transition: .4s all ease-in-out;
    }
  }
}