//
// COLORBOX aka frame-background
//

$frame-backgrounds: () !default;
$frame-backgrounds: map-merge(
  (
    'primary': (
      'background':       $primary-bg,
      'background-full':  $primary,
      'color':            $body-color,
      'link-color':       $link-color,
      'link-hover-color': $link-hover-color
    ),
    'secondary': (
      'background':       $secondary-bg,
      'background-full':  $secondary,
      'color':            $body-color,
      'link-color':       $link-color,
      'link-hover-color': $link-hover-color
    ),
    'turquoise': (
      'background':       $turquoise-bg,
      'background-full':  $turquoise,
      'color':            $body-color,
      'link-color':       $link-color,
      'link-hover-color': $link-hover-color
    ),
    'rose': (
      'background':       $rose-bg,
      'background-full':  $rose,
      'color':            $body-color,
      'link-color':       $link-color,
      'link-hover-color': $link-hover-color
    ),
    'orange': (
      'background':       $orange-bg,
      'background-full':  $orange,
      'color':            $body-color,
      'link-color':       $link-color,
      'link-hover-color': $link-hover-color
    ),
  ),
  $frame-backgrounds
);


@each $name, $settings in $frame-backgrounds {
  .frame-background-#{$name} {
    $tmp-color: map-get($settings, 'color');
    $tmp-background: map-get($settings, 'background');
    $tmp-link-color: map-get($settings, 'link-color');
    $tmp-link-hover-color: map-get($settings, 'link-hover-color');
    $tmp-background-full: map-get($settings, 'background-full');
    @if ($tmp-color) {
      --frame-color: #{$tmp-color};
    }
    @if ($tmp-background) {
      --sb-colorbox-bg: #{$tmp-background};
    }
    @if ($tmp-background-full) {
      --sb-colorbox-bg-full: #{$tmp-background-full};
    }
    @if ($tmp-link-color) {
      --frame-link-color: #{$tmp-link-color};
    }
    @if ($tmp-link-hover-color) {
      --frame-link-hover-color: #{$tmp-link-hover-color};
    }

    &.colorbox-bg {
      @if $name == secondary {
        $lighter-background: lighten($tmp-background, 10%);
        --sb-colorbox-bg: #{$lighter-background};
        background-color: var(--sb-colorbox-bg);
      } @else {
        background-color: var(--sb-colorbox-bg);
      }

      @include media-breakpoint-up(lg) {
        .frame > .tiles:first-child {
          margin-top: -30px;
        }
      }
    }

    .colorbox {
      --sb-list-dot-bg: #{$primary};
      background-color: var(--sb-colorbox-bg);
      border-radius: $border-radius-big;
      padding: 40px 60px;

      @include media-breakpoint-down(md) {
        padding: 40px;
      }
      @include media-breakpoint-down(sm) {
        padding: 30px;
      }

      &.colorbox-full-color {
        --sb-colorbox-bg: var(--sb-colorbox-bg-full);
      }
    }
  }
}

.frame-background-none {
  --frame-background: transparent;
}

.column-right {
  .colorbox {
    @include media-breakpoint-up(lg) {
      padding: 30px;
    }
  }
}

.frame-background-secondary,
.frame-background-rose,
.frame-background-orange {
  --sb-headline-intro-line: #{$primary};
}
.frame-background-primary,
.frame-background-turquoise {
  --sb-headline-intro-line: #{$secondary};
}
.frame-background-primary-dark {
  --sb-headline-intro-line: #{$rose};
}

.column-left {
  h1, h2 {
    .headline:first-child {
      @include icon-base($icon-mask: true, $position: relative, $show: after, $display: block) {
        @include svg-icon('#{$image-base-uri}line_long.svg', 16px, 100%, var(--sb-headline-intro-line));
        -webkit-mask-position: left;
        mask-position: left;
      }
    }
  }
}

.frame.colorbox-bg + .container {
  margin-top: var(--frame-spacing);
  @each $breakpoint in map-keys($frame-inner-spacing-scaling) {
    @include media-breakpoint-up($breakpoint) {
      --frame-spacing: var(--frame-spacing-#{$breakpoint});
    }
  }
}
.container + .frame.colorbox-bg {
  margin-top: var(--frame-spacing);
  @each $breakpoint in map-keys($frame-inner-spacing-scaling) {
    @include media-breakpoint-up($breakpoint) {
      --frame-spacing: var(--frame-spacing-#{$breakpoint});
    }
  }
}

.stage + .frame-background-secondary {

  .tiles {
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    .frame:first-child {
      padding-top: 0;
    }
  }
}