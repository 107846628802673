//
// GLOBAL VARS
//

// slick
$slick-loader-path: $image-base-uri;
$slick-font-path: $font-base-uri;
$slick-opacity-default: 1;

// klaro
$var-green1: $primary;
$var-green2: scale-color($var-green1, $lightness: 20%);
$var-green3: scale-color($var-green2, $lightness: 20%);
$cm-space: (
  xs: 4px,
  sm: 8px,
  md: 20px,
  lg: 24px,
  xl: 48px,
  xxl: 96px
);