//
// LINK WALL
//

.link-wall {
  &.link-wall-grid {
    @include media-breakpoint-down(lg) {
      grid-template-columns: 100%;
    }
  }

  .frame {
    --frame-spacing-xl: 10px;
    --frame-spacing-lg: 10px;
  }
}
.link-wall-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }
}
.wall-left {
  @include media-breakpoint-up(lg) {
    margin-top: 120px;
  }
}

.link-wall-item {
  --bs-heading-color: #{$primary-dark};
  --sb-wall-overlay-bg: #{red($primary), green($primary), blue($primary)};
  display: block;
  border-radius: $border-radius-big;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  &.link-wall-item-colorbox {
    &:after, &:before {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      transition: background .3s ease-in-out;
      width: 100%;
      z-index: 1;
    }
    &:after {
      background: rgba(var(--sb-wall-overlay-bg));
      background: linear-gradient(0deg, rgba(var(--sb-wall-overlay-bg),1) 20%, rgba(var(--sb-wall-overlay-bg),0) 85%);
    }
    &:hover:before {
      background: rgba(var(--sb-wall-overlay-bg), .9);
    }
  }
  .overlay {
    bottom: 0;
    padding: 25px;
    position: absolute;
    width: 100%;
    z-index: 5;
  }
  &.frame-background-secondary {
    --sb-wall-overlay-bg: #{red($secondary-light), green($secondary-light), blue($secondary-light)};
  }
  &.frame-background-turquoise {
    --sb-wall-overlay-bg: #{red($turquoise), green($turquoise), blue($turquoise)};
  }
  &.frame-background-rose {
    --sb-wall-overlay-bg: #{red($rose), green($rose), blue($rose)};
  }
  &.frame-background-orange {
    --sb-wall-overlay-bg: #{red($orange), green($orange), blue($orange)};
  }
  &.frame-background-gray {
    --bs-heading-color: #{$primary};
    background-color: $gray-200;
    color: $body-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    transition: all .3s ease-in-out;

    &:hover {
      --bs-heading-color: #{$white};
      background-color: $primary;
      color: $white;

      .link-wall-icon:before,
      .link-wall-text p:after {
        background-color: $white;
      }
    }

    h3 {
      margin-bottom: 10px;
      transition: color .3s ease-in-out;
    }
  }
  &.link-wall-item-image {
    img {
      height: 100%;
      max-width: none;
      width: auto;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    &:not(.image-link-wall-teaser) {
      h2 {
        font-size: rem(16);
      }
    }
  }
}

.link-wall-teaser {
  --frame-color: #{$white};
  --frame-link-color: #{$white};
  --bs-heading-color: #{$white};
  --sb-headline-intro-line: #{$rose};
  --sb-colorbox-bg: #{$primary};

  @include media-breakpoint-up(xl) {
    .colorbox {
      padding: 180px 80px;
    }
  }
}

.link-wall-item-colorbox {
  h3 {
    font-size: rem(20);
  }
}

.link-wall-image {
  img {
    width: 100%;
  }
}
.link-wall-text {
  p {
    @include icon-base($icon-mask: true, $position: relative, $show:after) {
      @include svg-icon('#{$image-base-uri}arrow.svg', 12px, 9px, $primary);
      margin-left: 10px;
      transition: background .3s ease-in-out;
    }
  }
}

.link-wall-icon {
  @include icon-base($icon-mask: true, $position: relative) {
    transition: background .3s ease-in-out;
  };
  margin-bottom: 20px;

  &-paper_plane:before {
    @include svg-icon('#{$image-base-uri}icon_paper_plane.svg', 51px, 73px, $primary);
  }
  &-hat:before {
    @include svg-icon('#{$image-base-uri}icon_hat.svg', 58px, 83px, $primary);
  }
}

@include media-breakpoint-down(sm) {
  .link-wall-item-image {
    display: none;
  }
}