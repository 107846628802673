//
// HOUSE TEASER
//

.house-teaser {
  .colorbox-full-color {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding: 0;
    overflow: hidden;
  }
  .colorbox {
    height: 100%;
  }
  h2 {
    margin-bottom: 10px;
  }


  @include media-breakpoint-up(md) {
    h3 {
      font-size: rem(20);
    }
  }
  @include media-breakpoint-up(lg) {
    .colorbox-text {
      padding: 80px;
    }
  }
  @include media-breakpoint-up(xxxl) {
    .colorbox-text {
      padding: 80px 120px;
    }
  }
  @include media-breakpoint-down(lg) {
    .colorbox-text {
      margin-top: 20px;
    }
  }
}